body {
  font-family: 'Times New Roman Bolt';
  background-color: #fdf3f9;
}

::-webkit-scrollbar
{
  display: none;
  width: 12px;
}
::-webkit-scrollbar-thumb
{
  background:linear-gradient(315deg, #D92938 0%, #D91A1A 25%, #A61414  50%, #591515 75%, #1C2326 100%);
  border-radius: 6px;
}

.arrowRight{
  display: flex;
  right: 0px;
  top: 140px;
  position: absolute;
  align-items: center;
  z-index: 10;
  animation: movearrowRight 1s infinite;
}

@keyframes movearrowRight{
  0%   {right: 0px;}
  33%  {right: 10px;}
  99%  {right: 0px;}
}


.arrowDown{
  display: flex;
  right: 0px;
  top: 100px;
  position: absolute;
  align-items: center;
  z-index: 10;
  animation: movearrowDown 1s infinite;
}

@keyframes movearrowDown{
  0%   {top: 100px;}
  33%  {top: 200px;}
  99%  {top: 100px;}
}

.timeline::after{
  content: '';
  position: absolute;
  width: 0.3vw;
  height: 100%;
  background: linear-gradient(#d9ced5 0%,#e70022 10%, #e70022 100%);
  top: 0;
  left: 50%;
  margin-left: -0.2vw;
  animation: moveline 6s linear forwards;
}

@keyframes moveline{
  0%{
    height: 0;
  }
  100%{
    height: 100%;
  }
}

.hidden{
  opacity: 0;
  transition: all 1s;
}

.show {
  opacity: 1;
}